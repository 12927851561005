<template>
  <div class="my-account">
    <div class="header-menu va-row">
      <div class="va-row">
        <div class="flex container-ddh-menu xs12 with-bottom va-row">
          <div class="flex xs12 no-padding header-back-button">
            <router-link :to="{ name: 'manager-books' }" class="btn-back">
              <!-- <span class="fa fa-arrow-left"></span> -->
              <svg
                width="22"
                height="16"
                viewBox="0 0 22 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.74323 1L9.03341 2.33967L4.49325 7.05271H21.0103V8.94711H4.49325L9.03341 13.6603L7.74288 15L0.999832 8L7.74323 1Z"
                  fill="#AFA2BA"
                  stroke="#AFA2BA"
                  stroke-width="1.44"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </router-link>

            <h1>
              {{ bookInfo.name }}
            </h1>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>

    <div class="container-dhh va-row">
      <div class="flex md12 xs12 no-padding">
        <h4></h4>
      </div>
      <div class="flex md12 xs12 no-padding">
        <div class="ui-grid">
          <div class="my-data-table va-row" id="cards">
            <div class="flex xs12 no-padding fiscal-notes">
              <div class="tab">Livros disponíveis</div>
              <div class="body-spacing">
                <div class="va-row">
                  <div class="sets-list va-row">
                    <div class="products-list" v-if="bookInfo">
                      <div class="va-row">
                        <div class="flex md3 xs12 text-left padding-zero-book">
                          <div class="">
                            <img
                              :src="bookInfo.thumbnail"
                              alt=""
                              width="100%"
                            />
                          </div>
                        </div>

                        <div class="flex md6 xs12 book-description">
                        

                          <div>
							  <h4><span class="nome-da-marca">{{ book.brand.name }}</span></br>{{ bookInfo.name }}</h4>
                            <div
                              class="description short"
                              v-html="bookInfo.content_text_2"
                            ></div>
                            <!-- <hr /> -->
                            <div
                              class="description long"
                              v-html="bookInfo.content_text"
                            >
                              <p>{{ bookInfo.content_text }}</p>
                            </div>
                          </div>

                          <br />
                        </div>

                        <!-- <div class="flex md12 complement-material">
                          <div
                            class="panel"
                            v-if="
                              bookInfo.extra_files &&
                              bookInfo.extra_files.length > 0
                            "
                          >
                            <div
                              class="flex xs12 no-padding text-left button-area"
                              v-for="file in bookInfo.extra_files"
                              :key="file.id"
                            >
                              <div class="va-row">
                                <div
                                  class="
                                    flex
                                    xs8
                                    text-left
                                    no-padding
                                    material-complement
                                  "
                                >
                                  <div>
                                    <h5>Material complementar</h5>
                                     <h4>{{ file.name }}</h4>
																		<div
																			class="description"
																			v-html="file.description"
																		></div> 
                                  </div>
                                </div>
                                <div class="flex xs4 text-right box-download">
                                  <a
                                    class="btn btn-white text-uppercase"
                                    target="_blank"
                                    :href="file.url"
                                    ><i class="fa fa-download"></i> BAIXAR</a
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { required, minLength, between } from 'vuelidate/lib/validators'
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";

// import VuesticTreeRoot from './VuesticTreeRoot.vue'
// import VuesticTreeNode from './VuesticTreeNode'
// import VuesticTreeCategory from './VuesticTreeCategory'

const axios = require("axios");

export default {
  name: "peoples",
  directives: { mask },
  computed: {
    ...mapGetters({
      managerCredential: "managerCredential",
    }),
  },

  data() {
    return {
      bookInfo: null,
    };
  },
  mounted() {
    const self = this;

    let config = {
      headers: {
        Authorization: "Bearer " + this.managerCredential.token,
      },
    };
    axios
      .get(process.env.VUE_APP_ROOT_API + "/products/" + self.book.id, config)
      .then((response) => {
        self.bookInfo = response.data.data.product;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },

  props: ["book"],

  methods: {
    jsonCopy(src) {
      return JSON.parse(JSON.stringify(src));
    },

    showSector(school, type) {
      this.sectorModalType = type;

      let copy = this.jsonCopy(school);
      this.sectorSelected = copy;
      this.$refs.largeModal.open();
    },

    // showGrade (grade, school, type) {
    //   this.gradeModalType = type
    //   this.gradeSelected = this.jsonCopy(grade);

    //   this.schoolSelected = school
    //   this.$refs.gradeModal.open()
    // },

    closeModal() {
      if (this.$refs.largeModal) {
        this.$refs.largeModal.close();
      }

      if (this.$refs.gradeModal) {
        this.$refs.gradeModal.close();
      }
    },

    clickOnFile() {
      this.$refs.files.click();
    },

    submitFiles() {
      let formData = new FormData();

      for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        formData.append("csv", file);
      }

      axios
        .post(process.env.VUE_APP_ROOT_API + "/schools/import", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + this.managerCredential.token,
          },
        })
        .then(function () {
          alert("Upload feito com sucesso");
        })
        .catch(function () {
          alert("Erro, upload falhou");
        });
    },

    handleFileUpload() {
      this.files = this.$refs.files.files;
      this.submitFiles();
    },

    doLogin(email) {
      const self = this;

      self.login.username = email;
      //self.$validator.validateAll().then(result => {

      //if(result){

      axios
        .post(process.env.VUE_APP_ROOT_API + "/users/login", self.login)
        .then(function (response) {
          // if(response.data.success === "true"){
          // } else {
          // }

          let userCredential = {
            token: response.data.data.token,
            project_id: response.data.data.user.project_id,
            role_id: response.data.data.user.role_id,
          };

          if (userCredential.role_id === 20) {
            // school manager
            self.$store.commit("SAVE_USER_CREDENTIALS", userCredential);
            self.$router.push({ name: "projects" });
          }
        })
        .catch(function (error) {
          let fieldError = {
            field: "error",
            msg: error.response.data.message,
            rule: "error", // optional
          };
          self.$validator.errors.add(fieldError);
        });

      //}

      //});
    },

    showSubscriberEditing() {
      this.subscriberEditing = false;
    },
    hideSubscriberEditing() {
      this.subscriberEditing = false;
    },

    scrollParent(slug) {
      this.$router.push({ name: slug });
    },
  },
};
</script>
<style lang="scss" scoped>
.schedule-list {
  text-align: center;

  .bullet {
    display: flex;
    width: 110px;
    height: 110px;
    border-radius: 60px;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    &.blue {
      background-color: rgb(74, 144, 226);
      color: #fff;
    }

    &.purple {
      background-color: rgb(116, 106, 208);
      color: #fff;
    }

    &.purple-green {
      background-color: rgb(0, 207, 113);
      color: #fff;
    }
  }

  .blocks {
    padding: 10px 15px;
    margin-bottom: 15px;

    &.blue {
      background-color: rgb(74, 144, 226);
      color: #fff;
    }

    &.purple {
      background-color: rgb(116, 106, 208);
      color: #fff;
    }

    &.purple-green {
      background-color: rgb(0, 207, 113);
      color: #fff;
    }
  }
}
.btn-login {
  background-color: transparent;
  border: 0px;
  color: #4965bc;
}
.hide {
  display: none;
}
@media (max-width: 580px) {
  .btn-primary {
    width: 100%;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 5px;
    }
  }
}

.fiscal-notes ::v-deep .form-group:last-child {
  margin-bottom: 10px !important;
}

.error-label {
  color: red;
  text-align: left;
  margin-bottom: 10px;
}
small {
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  text-indent: 15px;
  margin-top: -5px;
  display: block;
  margin-bottom: 10px;
  // padding-left: 15px;
}
h5 {
  font-family: "Nunito";
  font-size: 20px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  color: #4965bc;
  margin-top: 8px;
}
.address-info {
  margin-bottom: 15px;
}
.editing {
  margin-top: 15px;
}
.radio-button-wrapper {
  padding: 14px 11px;
  margin-top: 10px;

  @media (max-width: 990px) {
    padding: 20px 19px;
    margin-top: 10px;
  }
}
// .header-menu-club {
// 	h1 {
// 		font-family: "Nunito";
// 		font-size: 40px;
// 		font-weight: 900;
// 		font-style: normal;
// 		font-stretch: normal;
// 		line-height: 0.7;
// 		letter-spacing: normal;
// 		color: #000000;
// 		margin-top: 50px;
// 		margin-bottom: 20px;

// 		@media (max-width: 990px) {
// 			font-size: 18px;
// 		}
// 	}
// }
.add-new-card {
  border-radius: 10px;
  border: dashed 2px #2cd3d8;
  background-color: #ffffff;
  font-family: Nunito;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 3.5;
  letter-spacing: 0.7px;
  text-align: center;
  color: #4965bc;
  margin-bottom: 20px;
  padding: 15px 0px;
  cursor: pointer;

  .plus {
    font-family: "Nunito";
    font-size: 35px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.7px;
    text-align: center;
    color: #ffffff;
    width: 40px;
    height: 40px;
    transform: rotate(-180deg);
    border-radius: 40px;
    border: solid 1px #4965bc;
    background-color: #4965bc;
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    line-height: 36px;
  }
}

// Default of page

h4 {
  font-family: "Nunito";
  font-size: 24px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: 1.3px;
  color: #000000;
  margin-bottom: 0;
}
// .header-menu {
// 	background-color: white;

// 	h1 {
// 		font-family: "Nunito";
// 		font-size: 40px;
// 		font-weight: 900;
// 		font-style: normal;
// 		font-stretch: normal;
// 		line-height: 0.7;
// 		letter-spacing: normal;
// 		color: #000000;
// 		margin-top: 50px;
// 		margin-bottom: 20px;

// 		@media (max-width: 990px) {
// 			font-size: 18px;
// 		}
// 	}
// }
.my-data {
  /* Facebook bar */
  .facebook-tag {
    height: 65px;
    border-radius: 5px;
    background-color: #3a5998;
    color: white;
    padding: 14px 20px;
    margin-bottom: 20px;

    svg {
      font-size: 26px;
      margin-right: 0px;
      float: left;

      path {
        fill: #fff;
      }
    }

    .name {
      display: inline-block;
      margin-top: 1px;
      line-height: 37px;
    }

    .facebook-toggle {
      float: right;
      display: block;
      margin-top: 5px;
    }
  }
}

.change-password {
  border-radius: 10px;
  border: solid 2px #f0ebf5;
  background-color: #ffffff;
  padding: 24px 20px;
  position: relative;

  h4 {
    font-size: 16px;
    color: #000000;
    margin-top: 0px;
    margin-bottom: 7px;
    font-family: "Roboto";

    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }

  h5 {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #afa2ba;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .floated-icon {
    float: left;
    width: 36px;
    height: 36px;
    font-size: 30px;

    svg {
      position: relative;
      top: -5px;
      left: -3px;

      path {
        fill: #afa2ba;
      }
    }
  }

  .new-password-group {
    margin-top: 20px;
    display: block;
  }

  .editor {
    right: 18px;
    top: 20%;
  }

  ::v-deep .form-group {
    margin-bottom: 20px;
    padding: 22px 20px;

    ::v-deep &:last-child {
      margin-bottom: 20px;
    }
  }
}

.my-data-table {
  h4 {
    margin-top: 50px;
    margin-bottom: 20px;
  }
}
.fiscal-notes {
  border-radius: 10px;
  border: solid 1px #f0ebf5;
  background-color: #ffffff;
  position: relative;
  border: solid 2px #f0ebf5;

  h4 {
    font-size: 16px;
    color: #68596e;
    margin-top: 0px;
    margin-bottom: 10px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: normal;
  }

  .body-spacing {
    padding: 24px;

    // @media (max-width: 990px) {
    // 	padding: 25px 15px 15px 15px;
    // }

    .btn-change-edit {
      border: none;
      color: #4965bc;
      background: transparent;
    }

    //  div:last-child{
    //   margin-bottom: 0px;
    // }
  }

  hr {
    border-top: solid 1px #f0ebf5;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .dual-inputs {
    padding: 0px 8px;
    margin-bottom: 20px;
  }

  .new-password-group {
    margin-top: 20px;
    display: block;
  }

  .editor {
    right: 18px;
    top: 20%;
  }

  .company-code {
    text-transform: capitalize;
  }

  ::v-deep .form-group {
    margin-bottom: 20px;
    padding: 22px 20px;

    ::v-deep &:last-child {
      margin-bottom: 20px;
    }
  }

  // .panel:last-child{
  //   margin-bottom: 0px;
  // }
}

.address-boxes {
  border-radius: 10px;
  border: solid 1px #f0ebf5;
  background-color: #ffffff;
  position: relative;
  border: solid 2px #f0ebf5;

  h4 {
    font-size: 16px;
    color: #000000;
    margin-top: 0px;
    margin-bottom: 10px;
    font-family: "Roboto";
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  svg {
    path {
      fill: #afa2ba;
    }
  }

  .clube {
    svg {
      path {
        fill: #fff;
      }
    }
  }

  .body-spacing {
    padding: 30px 30px;

    @media (max-width: 990px) {
      padding: 25px 15px 15px 15px;
    }
  }

  hr {
    border-top: solid 2px #f0ebf5;
  }

  .dual-inputs {
    padding: 0px 8px;
    margin-bottom: 20px;
  }

  .new-password-group {
    margin-top: 20px;
    display: block;
  }

  .editor {
    right: 18px;
    top: 20%;
  }

  ::v-deep .form-group {
    margin-bottom: 20px;
    padding: 22px 20px;

    ::v-deep &:last-child {
      margin-bottom: 20px;
    }
  }

  .more-address {
    border-radius: 10px;
    border: solid 1px #f0ebf5;
    background-color: #ffffff;
  }

  .address-row {
    padding: 16px 0px;
    position: relative;
    border-bottom: solid 1px #f0ebf5;
    b {
      font-size: 16px;
      color: #000000;
    }
    :last-child {
      border-bottom: none;
    }
  }
}

.address-row {
  position: relative;
}

.more-cards {
  .more-address {
    border-radius: 10px;
    border: solid 1px #f0ebf5;
    background-color: #ffffff;
  }
}

.panel {
  padding: 20px;
  border-radius: 10px;
  border: solid 2px #4965bc;
  background-color: #ffffff;
  overflow: hidden;
  margin-bottom: 20px;
  max-width: 100%;
  margin: 0 auto;
  margin-right: 0px;

  .header-panel {
    padding: 0px 20px;
    background-color: #9378f0;
    line-height: 33px;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #ffffff;
  }

  .clube img {
    margin-top: -5px;
  }

  b {
    font-size: 16px;
    color: #000000;
  }

  .panel-body {
    position: relative;
    padding: 10px 0px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #afa2ba;
    margin: 0px !important;

    .flex.xs1 {
      -ms-flex-preferred-size: 8.33333%;
      flex-basis: 6.33333%;
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      max-width: 6.33333%;
    }

    b {
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.31;
      letter-spacing: normal;
      color: #000000;
    }

    .editor {
      right: 18px;
      top: 20%;
    }
  }
}

.subscription-box {
  // padding: 30px !important;

  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #000000;

  .toggled {
    margin-top: 10px;
  }
}
.newsletter {
  // margin-top:40px;
  padding: 31px;
  border: solid 2px #f0ebf5;

  @media (max-width: 990px) {
    margin-top: 0px;
    padding: 15px;
  }
}

// Custom Component
.v-switch-button {
  ::v-deep &:before {
    content: "";
    display: block;
    position: relative;
    background-color: #fff;
    width: 6px;
    height: 6px;
    border-radius: 14.5px;
    margin-left: auto;
    margin-right: auto;
    top: 9px;
  }
}

.vue-js-switch {
  &.toggled {
    .v-switch-button {
      ::v-deep &:before {
        content: "";
        display: block;
        position: relative;
        background-color: #7ed321;
        width: 6px;
        height: 6px;
        border-radius: 14.5px;
        margin-left: auto;
        margin-right: auto;
        top: 9px;
      }
    }
  }
}

.name-box {
  @media (max-width: 980px) {
    padding: 0px !important;
  }
}
.lastname-box {
  @media (max-width: 980px) {
    padding: 0px !important;
  }
}

.products-list {
  .description {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height, or 131% */

    /* color / neutral / darkest */

    color: #68596e;

    &.long {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      margin-top: 8px;
      /* or 150% */

      /* color / neutral / darkest */

      color: #68596e;
    }
  }
}

.book-description {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.complement-material {
  align-items: center;
  display: flex;
}

.box-download {
  align-items: center;
  display: flex;
}

.material-complement {
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .flex.md3 {
    flex-basis: 13.666667%;
    flex-grow: 0;
    max-width: 13.666667%;
  }
}
@media (min-width: 768px) {
  .flex.md6 {
    flex-basis: 83.666667%;
    flex-grow: 0;
    max-width: 83.666667%;
    margin-left: 16px;
  }
}
.tab {
  /* color/neutral/light */

  background: #f4f2fb;
  border: 1px solid #dfdae4;
  border-radius: 16px 16px 0px 0px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #68596e;
  padding: 20px 22px;
}
.nome-da-marca {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #68596e;
}
.padding-zero-book {
  padding: 0px !important;
}
</style>
